import api from "../api/api";
import { TASK_ERROR, TASK_LOAD, TASK_LOAD_COUNT, TASK_SEND } from "./actionTypes";

export const loadTasks = (task) => ({
  type: TASK_LOAD,
  payload: task
});

export const taskError = (error) => ({
  type: TASK_ERROR,
  payload: error
});

export const sendTaskFetch = (task, currentUser) => (
  dispatch => {
    api.post("stats", {
      userId: currentUser.id,
      serviceId: task.id,
      dateOperation: new Date()
    })
      .then(res => dispatch(sendTask(task)))
      .catch(error => dispatch(taskError("Не вдалося з'єднатися з сервером")));
  }
);

export const sendTask = (task) => ({
  type: TASK_SEND,
  payload: task
});

export const loadTaskCount = (taskCount) => ({
  type: TASK_LOAD_COUNT,
  payload: taskCount
});

export const loadTaskAndCountFetch = (user) => (
  async dispatch => {
    try {
      const tasks = await api.get("service");
      dispatch(loadTasks(tasks.data))
    } catch {
      dispatch(taskError("Не вдалося завантажити операції"));
      return;
    }
    try {
      const count = await api.get("stats/count/" + user.id);
      dispatch(loadTaskCount(count.data));
    } catch {
      dispatch(taskError("Не вдалося завантажити дані про зроблені операції"));
    }
  }
)