import { combineReducers } from "redux";
import { getUser } from "../services/authService";
import {
  AUTH_LOAD_ERROR,
  AUTH_LOAD_USERS,
  AUTH_LOGIN,
  AUTH_LOGOFF,
  TASK_ERROR,
  TASK_LOAD,
  TASK_LOAD_COUNT,
  TASK_SEND
} from "./actionTypes";

const initialAuth = { users: [], currentUser: getUser(), error: null }

const authReducer = (state = initialAuth, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return { ...state, currentUser: action.payload, error: null }
    case AUTH_LOAD_ERROR:
      return { ...state, error: action.payload };
    case AUTH_LOAD_USERS:
      return {
        ...state,
        users: action.payload,
        error: null
      };
    case AUTH_LOGOFF:
      return { ...state, currentUser: null }
    default:
      return state;
  }
}

const initialReception = { error: null, tasks: [] };

const receptionReducer = (state = initialReception, action) => {
  switch (action.type) {
    case TASK_LOAD:
      return {
        error: null,
        tasks: action.payload.map(item => ({ ...item, count: 0 }))
      }
    case TASK_ERROR:
      return { ...state, error: action.payload };
    case TASK_SEND:
      return {
        error: null,
        tasks: state.tasks.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, count: item.count + 1 }
          } else {
            return item;
          }
        })
      };
    case TASK_LOAD_COUNT:
      const newTasks = state.tasks.slice();
      const counts = action.payload;
      for (let i = 0; i < counts.length; i++) {
        const task = newTasks.find(item => item.id === counts[i].serviceId);
        if (task) {
          task.count = counts[i].count;
        }
      }
      return {
        error: null,
        tasks: newTasks
      }
    default:
      return state;
  }
}

export const rootReducer = combineReducers({
  auth: authReducer,
  reception: receptionReducer
});