import { useDispatch, useSelector } from "react-redux"
import { ReceptionHeader } from "./ReceptionHeader";
import { logoffUser } from "../store/authActions";
import { useEffect } from "react";
import { sendTaskFetch, loadTaskAndCountFetch } from "../store/receptionActions";
import { ReceptionTask } from "./ReceptionTask";
import './Reception.css';
import logo from "../lib/img/Logo_2.png";
import { clearUser } from "../services/authService";

export const Reception = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const reception = useSelector(state => state.reception);
  const dispatch = useDispatch();

  const handleClick = (task) => {
    dispatch(sendTaskFetch(task, currentUser))
  };

  const logoff = () => {
    dispatch(logoffUser());
    clearUser();
  };

  useEffect(() => {
    dispatch(loadTaskAndCountFetch(currentUser));
  }, [dispatch, currentUser]);

  return (
    <div className="row">
      <div className="col-3">
        <img src={logo} alt="logo" style={{ width: "100%", margin: "1rem" }} />
      </div>
      <div className="col-9">
        <ReceptionHeader onExit={logoff} name={currentUser.name} />
        <div className="reception-section my-2">
          {reception.error && <div className="bg-danger p-2 m-2 text-white text-center rounded">{reception.error}</div>}
          {reception.tasks && reception.tasks.map(task => <ReceptionTask task={task} key={task.id} onClick={handleClick} />)}
        </div>
      </div>
    </div>
  )
}