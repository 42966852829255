import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from '../lib/img/Logo_1.png';
import { saveUser, getIsAccessUser } from '../services/authService';
import { authorizeUser, loadUsersFetch } from '../store/authActions';
import './Login.css';
import { UserSelector } from './UserSelector';

export const Login = () => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [selectedUser, setUser] = useState(null);
  const history = useHistory();
  const AccessUser = getIsAccessUser();
  const [isAccessUser, setIsAccessUser] = useState(false);

  useEffect(() => {
    dispatch(loadUsersFetch());
    getIpUser();
  }, [dispatch]);

  const login = () => {
    saveUser(selectedUser);
    dispatch(authorizeUser(selectedUser));
    history.push('/');
  }

  const getIpUser = () => {
    fetch('https://ipapi.co/json/')
    .then(d => d.json())
    .then((d) => {
      let isAccessUser = false;
      let ipUserJson = "";
      ipUserJson = d.ip;
      for (let i = 0; i < AccessUser.length; i++) {
          if(ipUserJson == AccessUser[i]){
              console.log("ipUserJson " + ipUserJson);
              console.log("AccessUser " + AccessUser[i]);
              isAccessUser = true;
          }
      }
      setIsAccessUser(isAccessUser)
  }
    );
  }

if(!isAccessUser)
  return (
    <>
      <img src={logo} alt="logo" className="login-logo" />
      <div className="login-wrapper">
        {auth.error && <div className="bg-danger text-white p-2 my-2 rounded">{auth.error}</div>}
      </div>
    </>
  );
else
  return (
    <>
      <img src={logo} alt="logo" className="login-logo" />
      <div className="login-wrapper">
        {/* {ipUserShow} */}
        {auth.error && <div className="bg-danger text-white p-2 my-2 rounded">{auth.error}</div>}
        <UserSelector users={auth.users} onSelect={setUser} />
        <button className="login-button" disabled={!selectedUser} onClick={login}>Вхід</button>
      </div>
    </>
  );
}