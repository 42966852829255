import { useState } from 'react';
import './UserSelector.css';

export const UserSelector = ({ users, onSelect }) => {
  const [selectedUser, setSelectedUser] = useState(-1);

  const setUser = (e) => {
    setSelectedUser(e.target.value);
    const user = users.find(u => u.id === Number(e.target.value));
    onSelect(user);
  }

  return (
    <div>
      <label className="selector-label">Користувач</label>
      <select className="selector-users" value={selectedUser} onChange={setUser}>
        <option value={-1} disabled>Оберіть користувача</option>
        {users && users.map(user => (
          <option key={user.id} value={user.id}>{user.name}</option>
        ))}
      </select>
    </div>
  );
}