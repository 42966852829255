import { TaskIndicator } from './TaskIndicator';
import './ReceptionTask.css';

export const ReceptionTask = ({ task, onClick }) => (
    <div className="row my-4">
        <button className="btn-task col-6" onClick={() => onClick(task)}>{task.name}</button>
        <div className="col-6 reception-indicator">
            <TaskIndicator count={task.count} />
        </div>
    </div>
)