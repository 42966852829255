import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Login } from './login/Login';
import { Reception } from './reception/Reception';
import { PrivateRoute } from './PrivateRoute';

function App() {
  return (
    <Router className="bg-app">
      <div className="container app">
        <Switch>
          <PrivateRoute exact path="/" component={Reception} />
          <Route exact path="/login" component={Login} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
