import api from "../api/api";
import { AUTH_LOAD_ERROR, AUTH_LOAD_USERS, AUTH_LOGIN, AUTH_LOGOFF } from "./actionTypes"

export const authorizeUser = (user) => ({
  type: AUTH_LOGIN,
  payload: user
});

export const loadUsersError = (error) => ({
  type: AUTH_LOAD_ERROR,
  payload: error
});

export const loadUsersFetch = () => (
  dispatch => {
    api.get("user")
      .then(res => dispatch(loadUsers(res.data)))
      .catch(error => dispatch(loadUsersError("Помилка з'єднання з сервером")))
  }
)

export const loadUsers = (users) => ({
  type: AUTH_LOAD_USERS,
  payload: users
});

export const logoffUser = () => ({ type: AUTH_LOGOFF });