import './TaskIndicator.css';

export const TaskIndicator = ({ count, step = 10 }) => {
    const percent = count / step;

    return (
        <div className="task-indicator">
            <div className="task-count">{count}</div>
            <div className="task-placeholder">0</div>
            <div className={`task-indicator-circle ${percent >= 1 ? "task-indicator-enabled" : ""}`} percent="1"></div>
            <div className={`task-indicator-circle ${percent >= 2 ? "task-indicator-enabled" : ""}`} percent="2"></div>
            <div className={`task-indicator-circle ${percent >= 3 ? "task-indicator-enabled" : ""}`} percent="3"></div>
            <div className={`task-indicator-circle ${percent >= 4 ? "task-indicator-enabled" : ""}`} percent="4"></div>
            <div className={`task-indicator-circle ${percent >= 5 ? "task-indicator-enabled" : ""}`} percent="5"></div>
            <div className={`task-indicator-circle ${percent >= 6 ? "task-indicator-enabled" : ""}`} percent="6"></div>
            <div className={`task-indicator-circle ${percent >= 7 ? "task-indicator-enabled" : ""}`} percent="7"></div>
            <div className={`task-indicator-circle ${percent >= 8 ? "task-indicator-enabled" : ""}`} percent="8"></div>
            <div className={`task-indicator-circle ${percent >= 9 ? "task-indicator-enabled" : ""}`} percent="9"></div>
            <div className={`task-indicator-circle ${percent >= 10 ? "task-indicator-enabled" : ""}`} percent="10"></div>
        </div>
    )
}