const userKey = "reception-user";

export const saveUser = (user) => {
    const jsonUser = JSON.stringify(user);
    localStorage.setItem(userKey, jsonUser);
}

export const clearUser = () => {
    localStorage.removeItem(userKey);
}

export const getUser = () => {
    const userJson = localStorage.getItem(userKey);
    if (userJson) {
        return JSON.parse(userJson);
    }
    return null;
}

export const getIsAccessUser =  () => {
    const AccessUser = ['91.225.111.253', '91.224.96.180'];
    return AccessUser;
}