import axios from "axios";

const apiUrl = process.env.NODE_ENV === 'production' ? "api/" : "https://vizareception.viza.kr.gov.ua/api/";

const api = axios.create({
    baseURL: apiUrl
});

api.defaults.headers = {
    "api-key": "c6b01ddd-aeb2-4938-82e0-cc1f4717f95d"
};

export default api;