import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(state => state.auth.currentUser)
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? <Component {...props} />
          : <Redirect to="/login" />
      } />
  )
}